<script setup>
import { isEqual, keyBy, sortBy, toLower } from 'lodash-es';

import { useAuthStore } from '~/auth/stores/auth.store';

import HawkHandsontable from '~/common/components/organisms/hawk-handsontable/hawk-handsontable.vue';

import useEmitter from '~/common/composables/useEmitter';

import { csvInjectionProtector } from '~/common/utils/common.utils.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useFamConstants } from '~/forms-as-module/composables/fam-constants.composable.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
});

const $t = inject('$t');
const $services = inject('$services');
const can_configure_reports = inject('can_configure_reports');

const emitter = useEmitter();

const auth_store = useAuthStore();
const dashboard_store = useDashboardStore();

const state = reactive({
  is_loading: true,
  prevent_watcher: false,
  response_data: null,
  nested_headers: null,
  columns: [],
});

const column_name_map = computed(() => keyBy(props.data?.data?.fields || [], 'name'));
const columns_widths_map = computed(() => props.data.data?.properties?.columns_widths || {});

const { getFormattedDate } = useFamConstants();

watch(() => props.data, async (new_val, old_val) => {
  if (new_val && !isEqual(new_val, old_val)) {
    if (state.prevent_watcher) {
      state.prevent_watcher = false;
      return;
    }
    await getData();
  }
}, { immediate: true, deep: true });

function flattenObject(obj, parentKey, res = {}) {
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      if (key === 'track_stock_data') {
        for (const subKey in obj[key]) {
          const newKey = `track_stock_${subKey}`;
          res[newKey] = obj[key][subKey];
        }
      }
      else if (key === 'bom') {
        for (const subKey in obj[key]) {
          const newKey = `bom_${subKey}`; // subKey is warehouse
          Object.values(obj[key][subKey]).forEach((item) => {
            res[`${newKey}_${item.block}`] = item.quantity;
          });
        }
      }
      else if (key === 'group_by_warehouses') {
        for (const subKey in obj[key]) {
          const newKey = `group_by_warehouses_${subKey}`; // subKey is warehouse
          Object.values(obj[key][subKey]).forEach((item) => {
            res[`${newKey}_${item.block}`] = item.quantity;
          });
        }
      }
      else {
        flattenObject(obj[key], key, res);
      }
    }

    else if (column_name_map.value[key]?.label) {
      res[column_name_map.value[key].label] = obj[key];
    }
    else {
      res[key] = obj[key];
    }
  }

  return res;
}

async function getData() {
  try {
    state.is_loading = true;
    state.nested_headers = [];
    const { data } = await $services.inventory_reports.inventory_dynamic_report({ body: { ...parseDateRanges(props.data.data), order_by: ['number'] } });
    if (data.items?.results?.length) {
      const flatten_data = data.items?.results?.map(item => flattenObject(item));

      let columns = props.data?.data?.fields?.map(field => ({ data: field.label, readOnly: true, text: field.label, width: columns_widths_map.value[field.label] || 150 })) || [];

      if (data.time_interval?.length) {
        data.time_interval.forEach((interval) => {
          columns.push({
            data: `track_stock_${interval}`,
            readOnly: true,
            text: interval,
            width: columns_widths_map.value[`track_stock_${interval}`] || 150,
          });
        });
      }
      if (Object.keys(data.bom_warehouses || {})?.length || Object.keys(data.group_by_warehouses || {})?.length) {
        let nestedHeaders = [];

        if (Object.keys(data.group_by_warehouses || {})?.length) {
          const columns_to_be_omitted = ['Delivered quantity', '% Delivered', 'Remaining quantity', 'Scope'];
          columns = columns.filter(col => !columns_to_be_omitted.includes(col.text));
        }

        const first_level = [{ label: '', colspan: columns.length }];
        const second_level = [...columns.map(column => ({ label: column.text, colspan: 1 }))];

        let processedWarehouses = new Set();
        const warehouseColspan = {};

        // Creating a compilation of warehouses
        Object.keys(data.bom_warehouses || {}).forEach((warehouse) => {
          processedWarehouses.add(warehouse);
          warehouseColspan[warehouse] = data.bom_warehouses[warehouse].length;
        });

        Object.keys(data.group_by_warehouses || {}).forEach((warehouse) => {
          if (!processedWarehouses.has(warehouse)) {
            processedWarehouses.add(warehouse);
            warehouseColspan[warehouse] = data.group_by_warehouses[warehouse].length;
          }
          else {
            warehouseColspan[warehouse] += data.group_by_warehouses[warehouse].length;
          }
        });

        processedWarehouses = sortBy(Array.from(processedWarehouses), item => toLower(item));

        // Adding bom columns and then qty columns for the applicable warehouses
        processedWarehouses.forEach((warehouse) => {
          first_level.push({ label: warehouse, colspan: warehouseColspan[warehouse] });

          if (data.bom_warehouses?.[warehouse]) {
            data.bom_warehouses[warehouse].forEach((item) => {
              second_level.push({ label: `${item.block} (BOM)`, colspan: 1 });
              columns.push({
                data: `bom_${warehouse}_${item.block}`,
                readOnly: true,
                text: `item.block`,
                width: columns_widths_map.value[`bom_${warehouse}_${item.block}`] || 150,
              });
            });
          }
          if (data.group_by_warehouses?.[warehouse]) {
            const grouping_columns_map = {
              delivered_quantity: 'Delivered quantity',
              physical_stock_quantity: 'Quantity',
              delivered_percent: '% Delivered',
              remaining_quantity: 'Remaining quantity',
              scope: 'Scope',
            };
            data.group_by_warehouses[warehouse].forEach((item) => {
              second_level.push({ label: $t(grouping_columns_map[item.block]) || item.block, colspan: 1 });
              columns.push({
                data: `group_by_warehouses_${warehouse}_${item.block}`,
                readOnly: true,
                text: item.block,
                width: columns_widths_map.value[`group_by_warehouses_${warehouse}_${item.block}`] || 150,
              });
            });
          }
        });

        nestedHeaders = [first_level, second_level];
        state.nested_headers = nestedHeaders;
      }
      state.columns = columns;
      state.response_data = flatten_data;
    }
    state.is_loading = false;
  }
  catch (err) {
    logger.error({ err });
    state.is_loading = false;
  }
}

const colHeaders = function (index) {
  return state.columns[index].text;
};

function hotSettings() {
  return {
    rowHeaders: true,
    ...(auth_store.check_split('inventory_reports_filters') && props.id !== 'preview'
      ? { dropdownMenu: ['filter_by_condition', 'filter_by_value', 'filter_action_bar'], filters: true }
      : { dropdownMenu: false, filters: false }),
    manualColumnResize: props.id === 'preview' ? true : can_configure_reports.value,
    manualColumnMove: false,
    manualRowMove: false,
    rowHeights: 26,
    viewPortRowRenderingOffset: 100,
    afterGetColHeader,
  };
}

function afterGetColHeader(col, TH) {
  if (state.columns[col]?.data?.startsWith('track_stock_'))
    Handsontable.dom.addClass(TH, 'track-stock-header');
}
function parseDateRanges(data) {
  const parsed_payload = { ...data };
  if (data?.track_stock_quantities)
    parsed_payload.track_stock_timerange_range = getFormattedDate(data.track_stock_timerange_type) || [];

  if (data?.fields?.length) {
    parsed_payload.fields = data.fields.map((field) => {
      if (field?.timerange_type)
        return { ...field, timerange_range: getFormattedDate(field.timerange_type) || [] };
      return field;
    });
  }

  return parsed_payload;
}
async function exportExcel() {
  try {
    const ExcelJS = await import('exceljs');
    const { saveAs } = await import('file-saver');

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Report');

    worksheet.columns = state.columns.map((column) => {
      let header = csvInjectionProtector(column.data.split(`_${column.text}`)?.[0] || '');
      if (['track_stock'].includes(header) || column.data === header)
        header = '';
      if (header.startsWith('bom_'))
        header = header.slice(4);
      return { header, id: column.data, key: column.data, width: 20 };
    });

    // Merging columns
    const header_values = worksheet.getRow(1).values.slice(1);
    const merged_cells = {};
    header_values.forEach((header, index) => {
      if (!merged_cells[header])
        merged_cells[header] = { start: index + 1 };
      else
        merged_cells[header].end = index;
    });
    for (const [header, range] of Object.entries(merged_cells)) {
      if (range.end) {
        worksheet.mergeCells(1, range.start, 1, range.end + 1);
        worksheet.getCell(1, range.start).value = header;
      }
    }
    // Styling parent columns
    worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => {
      if (cell?._value?.value) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'd9d9d9' },
        };
        cell.border = { style: 'thin', color: { argb: 'd9d9d9' } };
        cell.font = { bold: true };
      }
    });
    // Adding children columns
    const children_columns = state.columns.reduce((acc, curr) => {
      acc[curr.data] = csvInjectionProtector(curr.text);
      return acc;
    }, {});
    worksheet.addRow(children_columns);

    // Styling children columns
    worksheet.getRow(2).eachCell({ includeEmpty: true }, (cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: cell?._column?._key?.startsWith('track_stock_') ? 'FEF0C7' : 'd9d9d9' },
      };
      cell.border = { style: 'thin', color: { argb: 'd9d9d9' } };
      cell.font = { bold: true };
    });

    // Adding data to excel
    state.response_data.forEach((val) => {
      const formatted_data = state.columns.reduce((acc, curr) => {
        acc[curr.data] = csvInjectionProtector(String(val?.[curr?.data] || ''));
        return acc;
      }, {});
      worksheet.addRow(formatted_data);
    });

    const merged_cells_headers = Object.keys(merged_cells);
    if (merged_cells_headers.length === 1 && merged_cells_headers[0] === '')
      worksheet.spliceRows(1, 1);

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${props.data?.data?.name}.xlsx`);
  }
  catch (error) {
    logger.error('Failed to export', error);
  }
}

function columnResized(columns_widths) {
  state.prevent_watcher = true;
  const columns_width_by_key = state.columns.reduce((acc, col, idx) => {
    acc[col.data] = { size: columns_widths[idx], id: col.data };
    return acc;
  }, {});

  dashboard_store.set_is_editing_dashboard(true);
  dashboard_store.set_table_column_widths(
    props?.id,
    columns_width_by_key,
  );
  dashboard_store.set_is_editing_dashboard(false);
  state.columns = state.columns.map(col => ({ ...col, width: columns_widths_map.value[col.data] || 150 }));
  if (props.id !== 'preview')
    lazyDashboardUpdate();
}

function lazyDashboardUpdate() {
  $services.dashboard.patch({
    id: dashboard_store.current_dashboard.uid,
    body: dashboard_store.current_dashboard,
  });
}

onMounted(() => {
  if (props.id !== 'preview') {
    emitter.on('export-inventory-report', () => {
      exportExcel();
    });
  }
});
onUnmounted(() => {
  if (props.id !== 'preview')
    emitter.off('export-inventory-report');
});
</script>

<template>
  <div>
    <HawkLoader v-if="state.is_loading" container_class="m-1" />
    <div
      v-else-if="!state.is_loading && data.type === 'material_tracking' || data?.data?.type === 'material_tracking'"
      :class="id === 'preview' ? 'h-[calc(100vh-320px)]' : 'h-[calc(100vh-180px)]'"
    >
      <HawkHandsontable
        :data="state.response_data"
        :columns="state.columns"
        :apply-read-only-class="false"
        :hot-settings="hotSettings()"
        :hot-table-id="id"
        :col-headers="colHeaders" :height="id !== 'preview' ? '100%' : '450px'"
        :after-get-col-header="afterGetColHeader"
        :nested-headers="state.nested_headers"
        @after-columns-resized="columnResized"
      />
    </div>
  </div>
</template>

<style>
.handsontable THEAD TH.track-stock-header {
  background-color: rgba(254, 240, 199, 1) !important;
}
</style>

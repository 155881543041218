import { computed, inject, nextTick, ref } from 'vue';
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import { useAuthStore } from '~/auth/stores/auth.store';
import formBulkUpdate from '~/forms/components/form-bulk-update.vue';
import { FORM_PERMISSIONS } from '~/forms/constants';
import { getPageNameByRouteName } from '~/common/utils/common.utils';

export function useFormBulkUpdateMethods({ table_instance, forms_store, refresh = () => { } }) {
  const selected_forms = ref([]);
  const is_pdf_exporting = ref(false);
  const $t = inject('$t');
  const $toast = inject('$toast');
  const $track_event = inject('$track_event');
  const $services = inject('$services');
  const auth_store = useAuthStore();
  const route = useRoute();

  function clearBulkSelection() {
    table_instance.value.clearSelect();
  }

  function handleFormSelection(e) {
    selected_forms.value = e.map(forms_list_row => forms_list_row.original);
  }
  const { open, close } = useModal({
    component: formBulkUpdate,
    attrs: {
      forms_store,
      onClose() {
        close();
        clearBulkSelection();
      },
      onRefresh() {
        refresh();
      },
      selected_forms,
    },
  });

  async function formBulkDeleteHandler() {
    try {
      const selected_forms_uids = selected_forms.value.map(form => form.uid);
      await $services.forms.post({ body: { forms: { remove: selected_forms_uids } }, toast: { message: 'Form Instance deleted successfully' } });
      const form_detail = selected_forms.value[0];
      $track_event('Deleted', {
        mode: selected_forms_uids.length > 1 ? 'Bulk' : 'Single',
        view: getPageNameByRouteName(route.name),
        associated_with: `${form_detail?.target_element?.type?.toUpperCase()}`,
        integration: form_detail?.properties?.integration?.type || null,
      });
      clearBulkSelection();
      refresh();
    }
    catch (error) {
      logger.log(error);
    }
  }
  function deletePopup() {
    const form_text = selected_forms.value.length > 1 ? $t('forms') : $t('form');
    const content = `${$t('Are you sure you want to delete')} ${selected_forms.value.length} ${form_text} ? ${$t('This action cannot be undone.')}`;

    const { open: openDeletePopup, close: closeDeletePopup } = useModal({
      component: HawkDeletePopup,
      attrs: {
        header: $t('Delete Forms'),
        content,
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          try {
            await formBulkDeleteHandler();
            closeDeletePopup();
            refresh();
          }
          catch (err) {
            logger.log(err);
            $toast({
              title: 'Something went wrong',
              text: 'Please try again',
              type: 'error',
            });
          }
        },
      },
    });
    openDeletePopup();
  }
  function exportCTAHandler() {
    if (is_pdf_exporting.value)
      is_pdf_exporting.value = false;
    nextTick(() => {
      is_pdf_exporting.value = true;
    });
  }

  const bulk_update_options = computed(() => [
    ...((selected_forms.value.length === selected_forms.value.filter(form => form?.status?.submission_status === 'submitted').length)
      ? []
      : [
          {
            uid: 0,
            label: $t('Bulk Update'),
            on_click: open,
          },
        ]),
    ...(auth_store.check_permission(FORM_PERMISSIONS.V2_MODIFY_FORMS, route.params.asset_id)
      ? [
          { uid: 1, label: $t('Delete'), on_click: deletePopup },
        ]
      : []),
    ...(auth_store.check_split('forms_export_pdf')
      ? [
          { uid: 2, label: $t('Export PDF'), on_click: exportCTAHandler, disabled: selected_forms.value.length > 100, tooltip: selected_forms.value.length > 100 ? $t('Can not export more than 100 forms') : '' },
        ]
      : []
    ),
  ]);

  return {
    bulk_update_options, selected_forms, is_pdf_exporting, handleFormSelection, clearBulkSelection,
  };
}

<!-- eslint-disable vue/prop-name-casing -->
<script setup>
// --------------------------------- Imports -------------------------------- //
import { omit, pick } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import { useRoute, useRouter } from 'vue-router';
import DmsTransmitForm from '~/dms/components/documents/forms/dms-transmit-form.vue';
import { get_transmittal_status } from '~/dms/constants';
import { useTransmittalsStore } from '~/dms/store/transmittals.store.js';

// ---------------------------------- Props --------------------------------- //

const props = defineProps({
  document_details: {
    type: Object,
  },
  can_sync: {
    type: Boolean,
  },
});

// ---------------------------------- Emits --------------------------------- //

const emit = defineEmits(['close', 'refresh']);

// ---------------------------- Injects/Provides ---------------------------- //
const $t = inject('$t');

// ----------------------- Variables - Pinia - consts ----------------------- //
const transmittals_store = useTransmittalsStore();

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //
const router = useRouter();
const route = useRoute();
// ------------------------ Variables - Local - refs ------------------------ //
const slider_form$ = ref(null);
const is_dropdown_open = ref(false);
const { TRANSMITTAL_STATUS } = get_transmittal_status();

const state = reactive({
  is_loading: false,
  is_transmitting: false,
  draft_attachment: null,
});

// ---------------------- Variables - Local - reactives --------------------- //

// --------------------------- Computed properties -------------------------- //
const status_dropdown = computed(() => omit(transmittals_store.transmittal.review_status_set || {}, ['submitted', 'pending']));

// -------------------------------- Functions ------------------------------- //
function addDocument(document) {
  state.draft_attachment = document[0];
}

function removeDocument() {
  state.draft_attachment = null;
}

function checkPermission(type) {
  return props.document_details?.actions?.[type] || false;
}

async function saveForm() {
  try {
    state.is_loading = true;
    const transmittal_uid = route.params.transmittal_uid;
    const document_uid = props.document_details?.uid;
    const document = {
      ...props.document_details,
      ...slider_form$.value.data,
    };
    const payload = {
      uid: props.document_details.uid,
      comments: document?.comments_out,
      sync: document?.sync,
      status: slider_form$.value?.data?.status,
      attachment: state?.draft_attachment?.service
        ? {
            service_object: state?.draft_attachment?.service,
            name: state?.draft_attachment?.file_name,
            size: state?.draft_attachment?.file_size,
            type: state?.draft_attachment?.mime_type,
            ...(pick(state.draft_attachment, ['thumbnail_small', 'thumbnail_xsmall', 'thumbnail_large', 'thumbnail_small_size', 'thumbnail_xsmall_size', 'thumbnail_large_size'])),
          }
        : undefined,
    };
    const response = await transmittals_store.save_as_draft_transmittal({
      transmittal_uid,
      transmittals: { documents: [payload] },
    }, false);
    if (response) {
      const document_index = response.documents.findIndex(doc => doc.uid === document_uid);
      const updated_document = response.documents.find(doc => doc.uid === document_uid);
      transmittals_store.transmittal.documents[document_index] = updated_document;
    }
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.is_loading = false;
  }
}

const transmit_modal = useModal({
  component: DmsTransmitForm,
  attrs: {
    onClosed() {
      const attrs = omit(transmit_modal.options.attrs, ['content', 'header', 'sub_header', 'header_icon', 'button_text']);
      transmit_modal.options.attrs = attrs;
    },
  },
});

async function openTransmitModal() {
  try {
    transmit_modal.patchOptions({
      attrs: {
        header: $t('Transmit'),
        button_text: $t('Transmit'),
        confirm: async (data) => {
          await transmitTransmittal(data || {});
          transmit_modal.close();
        },
        onClose() {
          transmit_modal.close();
        },
      },
    });
    transmit_modal.open();
  }
  catch (err) {
    logger.error(err);
  }
}

async function transmitTransmittal({ description, supporting_documents }) {
  try {
    state.is_transmitting = true;
    const transmittal_uid = route.params.transmittal_uid;
    const document = {
      ...props.document_details,
      ...slider_form$.value.data,
    };

    const attachment = state?.draft_attachment;
    const payload = [{
      uid: document.uid,
      status: slider_form$.value?.data?.status,
      comments: document?.comments_out || '',
      service_object: attachment?.service,
      name: attachment?.file_name,
      file_name: attachment?.file_name,
      type: attachment?.mime_type,
      size: attachment?.file_size,
      ...(pick(attachment, ['thumbnail_small', 'thumbnail_xsmall', 'thumbnail_large', 'thumbnail_small_size', 'thumbnail_xsmall_size', 'thumbnail_large_size'])),
    }];
    await transmittals_store.transmit_transmittal({
      transmittal_uid,
      transmittals: {
        documents: payload,
        message: {
          body: description,
          attachments: supporting_documents,
        },
      },
    });
    emit('refresh');
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.is_transmitting = false;
  }
}
// -------------------------------- Watchers -------------------------------- //

// ----------------------------- Lifecycle Hooks ---------------------------- //

function formMounted(el$) {
  if (props.document_details?.uid) {
    const { draft } = props.document_details;
    state.draft_attachment = draft?.attachment
      ? {
          file_name: draft?.attachment?.name || draft?.attachment?.file_name,
          file_size: draft?.attachment?.size || draft?.attachment?.file_size,
          mime_type: draft?.attachment?.type,
          service: draft?.attachment?.service_object,
        }
      : null;
    el$.update({
      status: draft?.status || props.document_details?.status || 'pending_review',
      comments_out: checkPermission('can_review') || checkPermission('can_submit') ? draft?.comments : null,
      sync: draft?.sync || null,
    });
  }
}

const is_comments_updated = ref(false);

function formUpdated(e) {
  is_comments_updated.value = !!e;
}

const should_disabled_action_buttons = computed(() => {
  if (checkPermission('can_submit')) {
    if ((is_comments_updated.value || state.draft_attachment))
      return false;
    return true;
  }
  return false;
});
</script>

<template>
  <div>
    <Vueform
      ref="slider_form$"
      size="sm"
      :display-errors="false"
      :columns="{
        default: { container: 12, label: 12, wrapper: 12 },
        sm: { container: 12, label: 12, wrapper: 12 },
        md: { container: 12, wrapper: 12, label: 12 },
      }"
      @mounted="formMounted"
    >
      <div class="col-span-12 grid gap-4 border rounded-lg border-gray-300 bg-gray-25  p-4" :class="{ '!border-primary-600 !bg-primary-25': checkPermission('can_review') || checkPermission('can_submit') }">
        <div
          v-if="checkPermission('can_review') || checkPermission('can_submit')"
          class="flex justify-between items-center w-full col-span-12"
        >
          <div class="text-sm font-semibold text-gray-700">
            <div v-if="checkPermission('can_review')">
              {{ $t('Review document') }}
            </div>
            <div v-else-if="document_details?.approval_status === 'revise_resubmit'">
              {{ $t('Revise & resubmit') }}
            </div>
            <div v-else>
              {{ $t('Upload document') }}
            </div>
          </div>
          <div class="text-sm flex items-center">
            <IconHawkAlertTriangle class="mr-1 text-orange-500" />
            {{ $t('Due date') }}:
            <span class="text-orange-500 ml-1"> {{ document_details?.due_date ? $date(document_details?.due_date, 'DATE_ORDINAL') : '-' }} </span>
          </div>
        </div>
        <StaticElement v-if="document_details?.current_workflow_step?.name" name="current_step" :label="$t('Current step')">
          <hawk-badge color="blue">
            {{ document_details?.current_workflow_step?.name }}
          </hawk-badge>
        </StaticElement>

        <div class="grid grid-cols-12 col-span-12 ">
          <div class="col-span-12 font-medium text-gray-700 text-sm">
            {{ $t('Comments in') }}:
          </div>
          <div class="col-span-12 text-gray-700 text-sm">
            {{ document_details?.comments_in || '-' }}
          </div>
        </div>
        <TextareaElement
          v-if="checkPermission('can_review') || checkPermission('can_submit')"
          :label="$t('Comments out')"
          name="comments_out"
          :placeholder="$t('Enter message here')"
          @change="formUpdated"
        />
        <div v-else class="grid grid-cols-12 col-span-12 ">
          <div class="col-span-12 font-medium text-gray-700 text-sm">
            {{ $t('Comments out') }}:
          </div>
          <div class="col-span-12 text-gray-700 text-sm">
            {{ document_details?.draft?.comments || document_details?.comments_out || '-' }}
          </div>
        </div>
        <div v-if="checkPermission('can_review')" class="grid col-span-12 grid-cols-12 gap-2">
          <div class="col-span-4">
            <SelectElement
              v-if="status_dropdown"
              v-bind="{
                name: 'status',
                label: $t('Status'),
                rules: ['required'],
                items: status_dropdown,
                canClear: false,
                canDeselect: false,
                native: false,
                addClasses: {
                  SelectElement: {
                    select: {
                      container: `${TRANSMITTAL_STATUS[slider_form$?.data?.status]?.class || ''} !shadow-none !border-none`,
                    },
                  },
                },
              }"
              class="w-52"
              @open="is_dropdown_open = true"
              @close="is_dropdown_open = false"
            >
              <template #single-label="{ value }">
                <div class="text-sm text-gray-900 flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3">
                  <span class="text-white truncate overflow-hidden w-40">
                    {{ value.label }}
                  </span>
                </div>
              </template>
              <template #option="{ option }">
                <div class="flex items-center">
                  <span class="h-[10px] w-[10px] rounded-full mx-auto inline-block" :style="{ backgroundColor: TRANSMITTAL_STATUS[option.value].color }" />
                  <span class="ml-2">
                    {{ option.label }}
                  </span>
                </div>
              </template>
              <template #caret>
                <IconHawkChevronUp v-if="is_dropdown_open" class="mr-3 text-gray-600 text-lg caret text-white" />
                <IconHawkChevronDown v-else class="mr-3 text-gray-600 text-lg caret text-white" />
              </template>
            </SelectElement>
          </div>
          <StaticElement v-if="slider_form$?.data?.status === 'revise_resubmit' && (transmittals_store?.transmittal?.type === 'submission' || transmittals_store?.transmittal?.type === 'submission_renewal')" label=" ">
            <div class="text-sm font-regular">
              <span class="font-semibold">{{ $t("Note") }}:</span> {{ $t("The document will be sent back to the submitter for revised version") }}
            </div>
          </StaticElement>
          <div v-if="document_details?.has_delegate && document_details?.delegated_to?.user && checkPermission('can_review')" class="grid grid-cols-12 col-span-12">
            <div class="col-span-12 text-xs font-medium text-gray-500">
              <div class="flex items-center mb-1">
                <IconHawkInfoCircle class="w-4 h-4 mr-1" /> Delegated to <HawkMembers :members="document_details?.delegated_to?.user" type="badge" class="ml-2" />
              </div>
              <div>{{ $t('You can still review and change the status') }}</div>
            </div>
          </div>
        </div>
        <div v-else-if="!(checkPermission('can_review') || checkPermission('can_submit'))">
          <div class="col-span-12 font-medium text-gray-700 text-sm">
            {{ $t('Status') }}:
          </div>
          <transmittal-review-status :document="document_details" />
        </div>
        <div v-if="checkPermission('can_review')" class="grid grid-cols-12 col-span-12">
          <CheckboxElement name="has_upload_permission" :text="$t('Update document')" :default="!!document_details?.draft?.attachment" :description="$t('Uploading new document will replace the existing document')" />
        </div>
        <div
          v-if="slider_form$?.data?.has_upload_permission || checkPermission('can_submit')"
          class="grid grid-cols-12 col-span-12"
        >
          <div class="col-span-12 text-gray-700 text-sm font-medium mb-1">
            {{ $t('Upload') }}
          </div>
          <div class="col-span-12">
            <HawkAttachmentsList
              v-if="state.draft_attachment"
              class="w-[200px]"
              :items="[state.draft_attachment]"
              @delete="removeDocument"
            />
            <DocumentImportDropdown v-else :is_single_file="true" :trigger_props="{ type: 'default' }" trigger_text="Upload document" dropdown_position="bottom-right" @add-files="addDocument" />
          </div>
        </div>
        <div v-if="document_details?.uid === 'feedback' && state.draft_attachment" class="col-span-12">
          <HawkAttachmentsList
            class="w-[200px]"
            :items="[state.draft_attachment]"
            :can_delete="false"
            :can_download="false"
          />
        </div>
        <ToggleElement v-if="can_sync" name="sync" :label="$t('Sync document')" :description="$t('New version of the document will be synced immediately in the register')" class="mb-4" />
        <StaticElement v-if="document_details?.waiting_on?.number" label="">
          <span class="font-semibold">{{ $t('Note') }}:</span> {{ $t('This document is blocked by transmittal') }}:
          <hawk-button type="link" @click="router.push({ name: 'files-transmittals-detail', params: { asset_id: route.params.asset_id, transmittal_uid: document_details?.waiting_on?.uid } })">
            {{ document_details.waiting_on.number }}
          </hawk-button>
        </StaticElement>
      </div>
      <div
        v-if="checkPermission('can_review') || checkPermission('can_submit')"
        class="col-span-12"
      >
        <div class="flex justify-end w-full">
          <HawkButton type="text" text="Cancel" class="mr-3" @click="emit('close')">
            {{ $t('Cancel') }}
          </HawkButton>
          <HawkButton
            class="mr-3"
            type="outlined"
            :disabled="should_disabled_action_buttons"
            :loading="state.is_loading"
            @click="saveForm"
          >
            {{ $t('Save as draft') }}
          </HawkButton>
          <HawkButton :loading="state.is_transmitting" :disabled="checkPermission('can_review') ? slider_form$?.data?.status === 'pending_review' : should_disabled_action_buttons" @click="openTransmitModal">
            {{ $t('Send') }}
          </HawkButton>
        </div>
      </div>
    </Vueform>
  </div>
</template>

<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { useElementSize } from '@vueuse/core';
import { toString } from 'lodash-es';
import { inject, ref } from 'vue';
import { useCommonStore } from '~/common/stores/common.store';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import FormCompactView from '~/forms/components/form-compact-view.vue';
import { useFormsStore } from '~/forms/store/forms.store';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  extra_query: {
    type: Object,
    default: () => {},
  },
  content_height: {},
  is_modal: {
    default: false,
    type: Boolean,
  },
});

const emit = defineEmits(['loading']);

const common_store = useCommonStore();

const dashboard_store = useDashboardStore();

const $date = inject('$date');

const form_widget_header = ref(null);
const { height } = useElementSize(form_widget_header);

const forms_store = useFormsStore(`forms-${props.id}`);

function getHeight() {
  if (props.id === 'preview' && !props.is_modal)
    return ((props.data.h || 22) * 20) - 90;
  else if (!props.id && window.innerWidth < 1300)
  // fix for form list drilldown on smaller screens
    return 360;
  else
    return (props?.data?.h * 24.5) - (height.value + 20);
}

function updatePrintMap() {
  dashboard_store.update_print_map(props.id, {
    type: undefined,
    renderAt: `chart-container-${props?.id}`,
    width: '100%',
    height: '100%',
    dataFormat: 'json',
    chart_name: props?.data?.data?.name,
    renderType: 'forms-list',
    dimensions: {
      x: props?.value?.x?.label,
      y: props?.value?.y?.label,
    },
    dataSource: {
      dataset: forms_store.forms,
      total: forms_store.total_form_count,
      dashboard_index: props.data.i,
    },
  });

  const cols = columns_config.value.map(a => a.id);
  const forms = forms_store.forms;
  const print_data = forms.map(t => cols.map((a) => {
    if (a === 'due_date') {
      return $date(t[a], 'L_DATE_MED');
    }
    else if (a === 'members') {
      const users = getMembersAndTeams(t.members, t.teams).map((u) => {
        return common_store.get_user_or_team_name(u);
      });
      return toString(users);
    }
    else { return toString(t[a]); }
  }),
  );

  dashboard_store.update_new_print_map((props.data?.data?.name || 'untitled'), {
    widget_type: 'table',
    type: props.data?.data?.type,
    data: print_data,
  });
}
</script>

<template>
  <div>
    <div ref="form_widget_header">
      <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
        <slot name="header-title" />
        <slot name="header-actions" />
      </div>
    </div>
    <FormCompactView
      v-bind="props"
      :options="{ show_no_data_text: true }"
      :height="getHeight() || content_height"
      @update-print-map="updatePrintMap"
      @loading="emit('loading', $event)"
    />
  </div>
</template>
